import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';

const SearchBar = ({ onSearch, disabled, hasResults }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const animationFrameRef = useRef(null);
  const minWidth = 300;

  const handleSearch = useCallback((e) => {
    e?.preventDefault();
    if (!disabled && searchQuery.trim()) {
      console.log('SearchBar: Submitting search:', searchQuery.trim());
      onSearch({ query: searchQuery.trim() });
    }
  }, [onSearch, searchQuery, disabled]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!disabled && searchQuery.trim()) {
        console.log('SearchBar: Enter key pressed, submitting search');
        handleSearch();
      }
    }
  };

  const updateWidth = useCallback(() => {
    if (inputRef.current && formRef.current) {
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'pre';
      span.style.font = window.getComputedStyle(inputRef.current).font;
      span.textContent = searchQuery || inputRef.current.placeholder;
      document.body.appendChild(span);

      const textWidth = span.getBoundingClientRect().width;
      document.body.removeChild(span);

      const viewportWidth = window.innerWidth;
      const maxWidth = viewportWidth * 0.9;
      const newWidth = Math.min(Math.max(minWidth, textWidth + 80), maxWidth);

      animationFrameRef.current = requestAnimationFrame(() => {
        formRef.current.style.width = `${newWidth}px`;
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    const debouncedUpdateWidth = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeTimeoutRef.current = setTimeout(updateWidth, 100);
    };

    debouncedUpdateWidth();

    const resizeObserver = new ResizeObserver(debouncedUpdateWidth);
    if (formRef.current) {
      resizeObserver.observe(formRef.current);
    }

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [updateWidth]);

  return (
    <div className={`search-bar-container ${hasResults ? 'search-bar-results' : 'search-bar-centered'}`}>
      <form 
        ref={formRef} 
        onSubmit={handleSearch} 
        className="search-input-wrapper"
        autoComplete="off"
      >
        <input
          ref={inputRef}
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Search for Properties..."
          className="search-input"
          disabled={disabled}
          autoComplete="off"
        />
        <button
          type="submit"
          disabled={disabled || !searchQuery.trim()}
          className="search-button"
          onClick={handleSearch}
        >
          <Search size={24} />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;