import React, { useMemo } from 'react';

const PropertyItem = ({ property, onSelect, isSelected }) => {
  const handleClick = () => {
    onSelect(property);
  };

  const displayValue = (value, suffix = '') => {
    return value != null ? `${value}${suffix}` : 'N/A';
  };

  const formatAddress = (address, city, state, zip) => {
    const parts = [address, city, state, zip].filter(Boolean);
    return parts.join(', ');
  };

  const formatNumber = (number) => {
    return number ? number.toLocaleString() : 'N/A';
  };

  const getMapImageUrl = useMemo(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return (lat, lng, type) => {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=17&size=400x300&maptype=${type}&markers=color:red%7C${lat},${lng}&key=${apiKey}`;
    };
  }, []);

  const imageUrl = useMemo(() => {
    const { PropertyAddressFull, PropertyAddressCity, PropertyLatitude, PropertyLongitude } = property;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (PropertyAddressFull && PropertyAddressCity) {
      return `https://maps.googleapis.com/maps/api/streetview?size=400x300&location=${PropertyAddressFull}, ${PropertyAddressCity}&key=${apiKey}`;
    } else if (PropertyLatitude && PropertyLongitude) {
      return getMapImageUrl(PropertyLatitude, PropertyLongitude, 'satellite');
    } else {
      return null;
    }
  }, [property, getMapImageUrl]);

  return (
    <div 
      className={`property-card ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={property.PropertyAddressFull || 'Property'} className="property-image" />
      ) : (
        <div className="property-image-placeholder">No image available</div>
      )}
      <div className="property-item-details">
        <h3 className="property-address">
          {formatAddress(
            property.PropertyAddressFull,
            property.PropertyAddressCity,
            property.PropertyAddressState,
            property.PropertyAddressZIP
          )}
        </h3>
        <p className="property-type">{displayValue(property.PropertyUseGroup)}</p>
        {(property.building_sqft || property.land_acres) && (
          <p className="property-size">
            {property.building_sqft && (
              <span className="highlight">{formatNumber(property.building_sqft)} SF</span>
            )}
            {property.building_sqft && property.land_acres && (
              <span className="separator"> | </span>
            )}
            {property.land_acres && (
              <span className="secondary">{displayValue(property.land_acres, ' Acres')}</span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default PropertyItem;