import React, { useMemo } from 'react';
import { X } from 'lucide-react';

const PropertyDetails = ({ property, onClose }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const getMapImageUrl = useMemo(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return (lat, lng, type) => {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=17&size=600x400&maptype=${type}&markers=color:red%7C${lat},${lng}&key=${apiKey}`;
    };
  }, []);

  const streetViewUrl = useMemo(() => {
    if (!property?.latitude || !property?.longitude) return '';
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return `https://maps.googleapis.com/maps/api/streetview?size=400x200&location=${property.latitude},${property.longitude}&key=${apiKey}`;
  }, [property]);

  const roadMapUrl = useMemo(() => {
    if (!property?.latitude || !property?.longitude) return '';
    return getMapImageUrl(property.latitude, property.longitude, 'roadmap');
  }, [getMapImageUrl, property]);

  const satelliteMapUrl = useMemo(() => {
    if (!property?.latitude || !property?.longitude) return '';
    return getMapImageUrl(property.latitude, property.longitude, 'satellite');
  }, [getMapImageUrl, property]);

  if (!property) return null;

  const renderDetail = (label, value, formatter = (v) => v) => {
    if (value == null || value === '') return null;
    return (
      <p><span className="property-details-label">{label}:</span> {formatter(value)}</p>
    );
  };

  return (
    <div className="property-details">
      <div className="property-details-header">
        <h2 className="property-details-title">{property.address}</h2>
        <button onClick={onClose} className="property-details-close">
          <X className="h-6 w-6" />
        </button>
      </div>
      <div className="property-details-body">
        {satelliteMapUrl && (
          <div className="property-details-images">
            <img src={satelliteMapUrl} alt="Satellite Map" className="property-details-image" />
          </div>
        )}
        <div className="property-details-info">
          <div className="property-details-section">
            <h3 className="property-details-subtitle">Property Details</h3>
            {renderDetail("Address", property.address)}
            {renderDetail("City", property.city)}
            {renderDetail("State", property.state)}
            {renderDetail("ZIP", property.zip)}
            {renderDetail("Year Built", property.year_built)}
            {renderDetail("Building Area", property.building_sqft, (v) => `${v} sqft`)}
            {renderDetail("Land Area", property.land_acres, (v) => `${v} acres`)}
          </div>
          <div className="property-details-section">
            <h3 className="property-details-subtitle">Financial Details</h3>
            {renderDetail("Sale Price", property.sale_price, formatCurrency)}
            {renderDetail("Sale Date", property.sale_date)}
            {renderDetail("Improvement Value", property.improvement_value, formatCurrency)}
            {renderDetail("Land Value", property.land_value, formatCurrency)}
            {renderDetail("Total Value", property.total_value, formatCurrency)}
          </div>
        </div>
        <div className="property-details-maps">
          {streetViewUrl && (
            <img 
              src={streetViewUrl} 
              alt="Street View" 
              className="property-details-map"
            />
          )}
          {roadMapUrl && (
            <img 
              src={roadMapUrl} 
              alt="Street Map" 
              className="property-details-map"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;