import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import AppContent from './AppContent';
import './styles/modern.css';

const App = () => {
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  if (!clerkPubKey) {
    return <div>Error: Missing Clerk configuration</div>;
  }

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <Router>
        <AppContent />
      </Router>
    </ClerkProvider>
  );
};

export default App;