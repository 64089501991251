import useAuthenticatedFetch from '../hooks/useAuthenticatedFetch';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const apiService = {
  useApi() {
    const authenticatedFetch = useAuthenticatedFetch();
    const messageHandlers = new Set();

    const sendChatMessage = async (messageData) => {
      if (!messageData.query && !messageData.originalQuery) {
        throw new Error('Chat message cannot be empty');
      }

      try {
        const clientId = messageData.conversation_id;
        console.log('Sending chat message:', messageData);

        const response = await authenticatedFetch(`${API_URL}/api/rag/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'chat',
            query: messageData.query,
            conversation_id: clientId,
            original_query: messageData.originalQuery
          })
        });

        // Notify subscribers of the response
        messageHandlers.forEach(handler => handler({
          type: 'chat',
          data: response
        }));

        return response;
      } catch (error) {
        console.error('Error in sendChatMessage:', error);
        
        // Notify subscribers of the error
        messageHandlers.forEach(handler => handler({
          type: 'error',
          data: { error: error.message || 'Chat failed' }
        }));
        
        throw error;
      }
    };

    return {
      searchProperties: async (query, page = 1, pageSize = 10, cacheKey = null, conversationId) => {
        console.log('API searchProperties called with:', { query, page, pageSize, cacheKey, conversationId });
        
        try {
          const url = new URL('/api/rag/search', API_URL);
          console.log('Using API URL:', url.toString()); // Debug log

          const response = await authenticatedFetch(url.toString(), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query,
              conversation_id: conversationId,
              limit: pageSize,
              offset: (page - 1) * pageSize,
              cache_key: cacheKey
            })
          });

          const searchResults = response.search_results || {};
          const results = {
            features: searchResults.features || [],
            total: searchResults.total || 0,
            poi: searchResults.poi || [],
            context: response.data?.context || {},
            pagination: {
              page: page,
              pages: Math.ceil((searchResults.total || 0) / pageSize),
              total: searchResults.total || 0,
              cacheKey: response.pagination?.cache_key || cacheKey
            }
          };

          // Notify subscribers of the results
          messageHandlers.forEach(handler => handler({
            type: 'results',
            data: { message: 'Search completed successfully' },
            search_results: searchResults,
            pagination: results.pagination
          }));

          return results;
        } catch (error) {
          console.error('Error in searchProperties:', error);
          
          // Notify subscribers of the error
          messageHandlers.forEach(handler => handler({
            type: 'error',
            data: { error: error.message || 'Search failed' }
          }));
          
          throw error;
        }
      },

      getPropertyDetails: async (propertyId) => {
        try {
          const response = await authenticatedFetch(`${API_URL}/properties/${propertyId}`);
          return response;
        } catch (error) {
          console.error('Error fetching property details:', error);
          throw error;
        }
      },

      getNearbyProperties: async (lat, lon, distance) => {
        try {
          const response = await authenticatedFetch(`${API_URL}/properties/nearby?lat=${lat}&lon=${lon}&distance=${distance}`);
          return response;
        } catch (error) {
          console.error('Error fetching nearby properties:', error);
          throw error;
        }
      },

      // Keep subscription mechanism for components that need updates
      subscribeToMessages: (handler) => {
        messageHandlers.add(handler);
        return () => messageHandlers.delete(handler);
      },

      sendChatMessage,

      getChatHistory: async (conversationId) => {
        try {
          const protocol = window.location.protocol;
          const baseUrl = API_URL || `${protocol}//${window.location.hostname}:8000`;
          
          const response = await authenticatedFetch(`${baseUrl}/api/rag/chat/history/${conversationId}`);
          return response;
        } catch (error) {
          console.error('Error fetching chat history:', error);
          throw error;
        }
      },

      // These methods are no longer needed but kept as no-ops for compatibility
      disconnectWebSocket: () => {},
      ensureWebSocketConnection: async () => Promise.resolve()
    };
  }
};

export default apiService;