import React from 'react';
import { UserButton } from '@clerk/clerk-react';

const Header = ({user}) => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-title">
          <a href="/">
            <img src="images/gridlot-logo.png" alt="gridlot logo" className="logo" />
          </a>
        </div>
        <div className="user-actions">
          <UserButton />
        </div>
      </div>
    </header>
  );
};

export default Header;