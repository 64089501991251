import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingAnimation = ({ message, progress }) => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-spinner-container">
          <Loader2 className="animate-spin h-12 w-12 mb-4" />
        </div>
        <p className="text-lg font-semibold mb-4">{message}</p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div 
            className="progress-bar bg-primary h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;