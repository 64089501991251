import React, { useState, useRef, useEffect } from 'react';
import './ChatWindow.css';

const ChatWindow = ({ 
  api,  // This should be the result of apiService.useApi()
  conversationId,
  isMinimized = false,
  onToggleMinimize = () => {},
  onSearchResults,
  originalQuery
}) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ogChatMessage, setOgChatMessage] = useState('');
  
  const messagesEndRef = useRef(null);
  const hasInitialized = useRef(false);
  const connectionAttempted = useRef(false);
  const inputRef = useRef(null);

  // Combine the initialization effects
  useEffect(() => {
    if (originalQuery?.length > 0 && !hasInitialized.current && !connectionAttempted.current) {
      hasInitialized.current = true;
      connectionAttempted.current = true;
      setOgChatMessage(originalQuery);
      
      setMessages([{
        role: 'assistant',
        type: 'chat',
        content: `I found some properties matching your search...`,
        timestamp: new Date().toISOString()
      }]);
    }
  }, [api, conversationId, originalQuery, ogChatMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleMessage = (message) => {
    switch (message.type) {
      case 'connection_status':
        break;
        
      case 'processing':
        setMessages(prev => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.type === 'processing' && 
              lastMessage.content === message.data.message) {
            return prev;
          }
          return [...prev, {
            role: 'assistant',
            type: 'processing',
            content: message.data.message,
            timestamp: new Date().toISOString()  // Added timestamp for consistency
          }];
        });
        break;
        
      case 'results':
        setIsLoading(false);

        setMessages(prev => {
          const filteredMessages = prev.filter(msg => msg.type !== 'processing');
          return [...filteredMessages, {
            role: 'assistant',
            type: 'results',
            content: message.data.message,
            timestamp: new Date().toISOString()
          }];
        });
        
        if (message && message.search_results) {
          onSearchResults(message);
          setOgChatMessage(message.data.new_query);
        }
        break;
        
      case 'error':
        setMessages(prev => {
          const filteredMessages = prev.filter(msg => msg.type !== 'processing');
          return [...filteredMessages, {
            role: 'assistant',
            type: 'error',
            content: message.data.error || 'An error occurred',
            timestamp: new Date().toISOString()  // Added timestamp for consistency
          }];
        });
        break;
        
      case 'chat':
        setMessages(prev => {
          const filteredMessages = prev.filter(msg => msg.type !== 'processing');
          return [...filteredMessages, {
            role: 'assistant',
            type: 'chat',
            content: message.data.message,
            timestamp: new Date().toISOString()
          }];
        });
        break;
        
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      role: 'user',
      type: 'user',
      content: inputValue.trim(),
      timestamp: new Date().toISOString()
    };

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);
      setInputValue('');

      setMessages(prev => [...prev, {
        role: 'assistant',
        type: 'processing',
        content: 'Thinking...',
        timestamp: new Date().toISOString()
      }]);

      const response = await api.sendChatMessage({
        query: userMessage.content,
        conversation_id: conversationId,
        originalQuery: ogChatMessage
      });

      if (response.message) {
        setMessages(prev => {
          const filteredMessages = prev.filter(msg => msg.type !== 'processing');
          return [...filteredMessages, {
            role: 'assistant',
            type: 'chat',
            content: response.message,
            timestamp: new Date().toISOString()
          }];
        });
      }

      if (response && response.search_results) {
        onSearchResults(response);
        handleMessage(response);
      }

    } catch (error) {
      console.error('Error sending chat message:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        type: 'error',
        content: error.message || 'Sorry, there was an error processing your message. Please try again.',
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return '';
      
      return date.toLocaleTimeString([], { 
        hour: 'numeric', 
        minute: '2-digit',
        hour12: true 
      });
    } catch (e) {
      return '';
    }
  };

  if (!ogChatMessage || ogChatMessage.length === 0) {
    return null;
  }

  if (isMinimized) {
    return (
      <div 
        className="chat-window-minimized" 
        onClick={onToggleMinimize}
        title="Open chat"
      >
        <i className="fas fa-comments" style={{ fontSize: '24px' }}></i>
      </div>
    );
  }

  return (
    <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
      <div className="chat-header">
        <div className="chat-title">Chat Assistant</div>
        <div className="minimize-button" onClick={onToggleMinimize} />
      </div>
      
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role} ${message.type}`}>
            <div className="message-content">
              {message.type === 'processing' ? (
                <div className="loading-animation">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              ) : (
                message.content
              )}
            </div>
            {message.timestamp && (
              <div className="message-timestamp">
                {formatTimestamp(message.timestamp)}
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-input-container">
        <form className="chat-input-form" onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button 
            type="submit" 
            className="send-button" 
            disabled={isLoading || !inputValue.trim()}
          >
            <svg viewBox="0 0 24 24">
              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;