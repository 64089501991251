import { useAuth } from '@clerk/clerk-react';

export default function useAuthenticatedFetch() {
  const { getToken } = useAuth();

  const authenticatedFetch = async (url, options = {}) => {
    const token = await getToken();
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  return authenticatedFetch;
}