import React from 'react';

const NoResultsMessage = () => {
  return (
    <div className="no-results-message" style={{
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      padding: '30px',
      borderRadius: '10px',
      color: 'white',
      textAlign: 'center',
    }}>
      <img src="/images/magnifying-glass.png" alt="Magnifying glass" style={{ width: '80px', height: '80px', marginBottom: '20px' }} />
      <h2 style={{ fontSize: '24px', marginBottom: '15px' }}>Oops! No results found</h2>
      <p style={{ fontSize: '16px', marginBottom: '10px' }}>We couldn't find any properties matching your search criteria.</p>
      <p style={{ fontSize: '16px', marginBottom: '10px' }}>This might be due to limited data in our system for your specific query.</p>
      <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Please try again with a different search term!</p>
    </div>
  );
};

export default NoResultsMessage;