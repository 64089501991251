import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Map from './components/Map';
import PropertyList from './components/PropertyList';
import PropertyDetails from './components/PropertyDetails';
import Login from './components/Login';
import LoadingAnimation from './components/LoadingAnimation';
import NoResultsMessage from './components/NoResultsMessage';
import apiService from './services/api';
import ChatWindow from './components/ChatWindow/ChatWindow';

const AppContent = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const api = apiService.useApi();
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [originalQuery, setOriginalQuery] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [poiInfo, setPoiInfo] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [loadingInterval, setLoadingInterval] = useState(null);
  const pageSize = 10;
  const searchTimeoutRef = useRef(null);
  const isSearchingRef = useRef(false);
  const debounceTimerRef = useRef(null);
  const [conversationId] = useState(String(Date.now()));
  const [isChatMinimized, setIsChatMinimized] = useState(false);

  const fetchProperties = useCallback(async (query, page, pageSize, isPagination = false, conversationId) => {
    if (isSearchingRef.current) {
      return;
    }
    
    try {
      isSearchingRef.current = true;
      setIsSearching(true);
      
      /* if (!isPagination) {
        api.disconnectWebSocket();
      } */
      
      // Different loading states for new search vs pagination
      if (!isPagination) {
        setLoadingProgress(0);
        setNoResults(false);
        setHasSearched(false);
        
        const animateLoading = async () => {
          const loadingMessages = [
            'Analyzing your query...',
            'Searching our vast database...',
            'Crunching the numbers...',
            'Filtering results...',
            'Preparing property details...',
            'Almost there...'
          ];
          const totalSteps = loadingMessages.length;
          const progressPerStep = 95 / totalSteps;

          for (let i = 0; i < loadingMessages.length; i++) {
            if (!isSearchingRef.current) break;
            
            setLoadingMessage(loadingMessages[i]);
            const endProgress = (i + 1) * progressPerStep;

            await new Promise(resolve => {
              const interval = setInterval(() => {
                setLoadingProgress(prev => {
                  const newProgress = prev + Math.random() * 0.3;
                  if (newProgress >= endProgress) {
                    clearInterval(interval);
                    resolve();
                  }
                  return Math.min(newProgress, endProgress);
                });
              }, 100);
              setLoadingInterval(interval);
            });

            await new Promise(resolve => setTimeout(resolve, Math.random() * 1000 + 500));
          }
        };

        animateLoading();
      } else {
        setLoadingMessage('Loading more results...');
        setLoadingProgress(50);
      }

      const results = await api.searchProperties(query, page, pageSize, null, conversationId);
      
      if (results?.features) {
        setProperties(results.features);
        setTotalProperties(results.total || 0);
        setCurrentPage(page);
        setSearchQuery(query);
        
        // Only reset selection for new searches, not pagination
        if (!isPagination) {
          setSelectedProperty(null);
          setSelectedPropertyDetails(null);
        }
        
        setHasSearched(true);
        setNoResults(results.features.length === 0);
        if (!isPagination) {
          setPoiInfo(results.poi);
        }
      } else {
        throw new Error("Unexpected response from server");
      }

    } catch (error) {
      console.error('Error fetching properties:', error);
      setProperties([]);
      setTotalProperties(0);
      setLoadingMessage('Error occurred. Please try again.');
      setNoResults(true);
      setHasSearched(true);
      
      api.disconnectWebSocket();
    } finally {
      isSearchingRef.current = false;
      setIsSearching(false);
      setLoadingProgress(100);
      if (loadingInterval) {
        clearInterval(loadingInterval);
        setLoadingInterval(null);
      }
    }
  }, [api, loadingInterval]);

  const handleSearch = useCallback((searchData) => {
    if (!searchData.query || isSearchingRef.current) {
      return;
    }

    setSearchQuery(searchData.query);
    setOriginalQuery(searchData.query);
    
    // Clear any existing timeouts
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Execute search immediately
    setCurrentPage(1);
    fetchProperties(searchData.query, 1, pageSize, false, conversationId);
  }, [fetchProperties, pageSize, conversationId]);

  const fetchPropertyDetails = useCallback(async (propertyId) => {
    try {
      const details = await api.getPropertyDetails(propertyId);
      setSelectedPropertyDetails(details.properties);
    } catch (error) {
      console.error('Error fetching property details:', error);
    }
  }, [api]);

  const handleSelectProperty = useCallback((property) => {
    setSelectedProperty(property);
  }, []);

  const handleViewDetails = useCallback((property) => {
    if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
        if (property && property.attomid) {
            setIsLoadingDetails(true);
            fetchPropertyDetails(property.attomid)
                .finally(() => setIsLoadingDetails(false));
        } else {
            console.error("Invalid property object:", property);
        }
    }, 300);
  }, [fetchPropertyDetails]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      fetchProperties(originalQuery, newPage, pageSize, true, conversationId); // Pass isPagination flag
    }
  }, [currentPage, fetchProperties, pageSize, conversationId, originalQuery]);

  const handleClosePropertyDetails = useCallback(() => {
    setSelectedPropertyDetails(null);
  }, []);

  const handleChatSearchResults = useCallback((results) => {

    if (results?.search_results?.features) {
      setIsSearching(false);
      setLoadingProgress(100);
      if (loadingInterval) {
        clearInterval(loadingInterval);
        setLoadingInterval(null);
      }

      api.disconnectWebSocket();

      // Update all necessary states
      setProperties(results.search_results?.features);
      setTotalProperties(results.search_results?.total || 0);
      setCurrentPage(1); // Reset to first page
      setSelectedProperty(null); // Clear selected property
      setSelectedPropertyDetails(null); // Clear property details
      setHasSearched(true); // Important for visibility
      setNoResults(results.search_results?.features.length === 0);
      setPoiInfo(results.search_results?.poi);
      
      // Update search query if provided
      if (results.data?.new_query) {
        setSearchQuery(results.data.new_query);
        setOriginalQuery(results.data.new_query);
      }
    }
  }, [api, loadingInterval]);

  const handleToggleChat = useCallback(() => {
    setIsChatMinimized(prev => !prev);
  }, []);

  useEffect(() => {
    // Capture current ref values at the time the effect runs
    const searchTimeout = searchTimeoutRef.current;
    const debounceTimer = debounceTimerRef.current;
    const currentLoadingInterval = loadingInterval;

    return () => {
      clearTimeout(searchTimeout);
      clearTimeout(debounceTimer);
      if (currentLoadingInterval) {
        clearInterval(currentLoadingInterval);
      }
      api.disconnectWebSocket();
    };
  }, [api, loadingInterval]);

  if (!isLoaded) {
    return <LoadingAnimation message="Initializing..." />;
  }

  return (
    <div className="app-container">
      <Header user={user} />
      <Routes>
        <Route 
          path="/login" 
          element={!isSignedIn ? <Login /> : <Navigate to="/" replace />} 
        />
        <Route
          path="/"
          element={
            isSignedIn ? (
              <main className="main-content" style={{
                position: 'relative',
                minHeight: '100vh',
              }}>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(/images/${!hasSearched ? 'bg.png' : (noResults ? 'sat-map-bg.png' : '')})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  zIndex: 1,
                  display: (!hasSearched || noResults) ? 'block' : 'none',
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }} />
                </div>
                {!hasSearched && (
                  <div className="home-screen-text" style={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: 'white',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    zIndex: 2,
                  }}>
                    <h1 className="search-headline" style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>
                      Skip the filters, just type and simplify your commercial property search.
                    </h1>
                  </div>
                )}
                <div className={`search-container ${hasSearched ? (properties.length > 0 ? '' : 'search-bar-top') : 'search-bar-centered'}`} style={{ zIndex: 2, position: 'relative' }}>
                  <SearchBar 
                    onSearch={handleSearch} 
                    disabled={isSearching}
                    hasResults={hasSearched && properties.length > 0}
                  />
                </div>
                {isSearching && <LoadingAnimation message={loadingMessage} progress={loadingProgress} />}
                {hasSearched && !noResults && (
                  <div className={`content-wrapper ${properties.length > 0 ? 'visible' : ''}`}>
                    <div className="map-container">
                      <Map 
                        properties={properties} 
                        selectedProperty={selectedProperty} 
                        onPropertySelect={handleSelectProperty}
                        onViewDetails={handleViewDetails}
                        poi={poiInfo}
                        isLoadingDetails={isLoadingDetails}
                      />
                    </div>
                    {selectedPropertyDetails ? (
                      <PropertyDetails 
                        property={selectedPropertyDetails} 
                        onClose={handleClosePropertyDetails} 
                      />
                    ) : (
                      <PropertyList 
                        properties={properties} 
                        onSelectProperty={handleSelectProperty}
                        selectedProperty={selectedProperty}
                        currentPage={currentPage}
                        totalProperties={totalProperties}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        searchQuery={searchQuery}
                      />
                    )}
                  </div>
                )}
                {noResults && (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                  }}>
                    <NoResultsMessage />
                  </div>
                )}
              </main>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
      
      {isSignedIn && hasSearched && (
        <ChatWindow
          api={api}
          conversationId={conversationId}
          onSearchResults={handleChatSearchResults}
          searchContext={properties}
          isMinimized={isChatMinimized}
          onToggleMinimize={handleToggleChat}
          originalQuery={originalQuery}
        />
      )}
    </div>
  );
};

export default AppContent;