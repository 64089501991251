import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useLoadScript, InfoWindow, Marker, Circle } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '90%',
  width: '100%'
};

const defaultCenter = {
  lat: 39.8283,
  lng: -98.5795
};

const Map = ({ properties, selectedProperty, onPropertySelect, onViewDetails, poi, isLoadingDetails }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [map, setMap] = useState(null);
  const [activePolygon, setActivePolygon] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);

  const isValidCoordinate = (lat, lng) => {
    return lat && lng && !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));
  };

  const getValidProperties = useCallback(() => {
    return properties.filter(property => 
      isValidCoordinate(property.PropertyLatitude, property.PropertyLongitude)
    );
  }, [properties]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const fitBounds = useCallback(() => {
    if (map && properties.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      const validProperties = getValidProperties();
      
      validProperties.forEach((property) => {
        bounds.extend({ 
          lat: parseFloat(property.PropertyLatitude), 
          lng: parseFloat(property.PropertyLongitude) 
        });
      });

      if (poi && isValidCoordinate(poi.latitude, poi.longitude)) {
        bounds.extend({ 
          lat: parseFloat(poi.latitude), 
          lng: parseFloat(poi.longitude) 
        });
      }

      map.fitBounds(bounds);
      if (map.getZoom() > 15) map.setZoom(15);
    }
  }, [map, properties, poi, getValidProperties]);

  useEffect(() => {
    if (map && properties.length > 0) {
      fitBounds();
    }
  }, [properties, map, fitBounds]);

  useEffect(() => {
    if (map && selectedProperty) {
      const latitude = selectedProperty.PropertyLatitude;
      const longitude = selectedProperty.PropertyLongitude;
      
      if (latitude && longitude) {
        const position = { 
          lat: parseFloat(latitude), 
          lng: parseFloat(longitude) 
        };
        map.panTo(position);
        map.setZoom(17);
        setActivePolygon(selectedProperty.attomid);
        setInfoWindowPosition(position);
      }
    }
  }, [selectedProperty, map]);

  const handlePolygonClick = (property) => {
    setActivePolygon(property.attomid);
    onPropertySelect(property);
    const position = { 
      lat: parseFloat(property.PropertyLatitude), 
      lng: parseFloat(property.PropertyLongitude) 
    };
    setInfoWindowPosition(position);
  };

  const handleViewDetails = (propertyId) => {
    console.log("Map - handleViewDetails called with ID:", propertyId);
    const property = properties.find(p => p.attomid === propertyId);
    if (property) {
        console.log("Map - Found property:", property);
        onViewDetails(property);
    } else {
        console.error("Property not found for ID:", propertyId);
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const validProperties = getValidProperties();

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={defaultCenter}
      zoom={4}
      onLoad={onLoad}
    >
      {validProperties.map((property) => (
        <Marker
          key={property.attomid}
          position={{
            lat: parseFloat(property.PropertyLatitude),
            lng: parseFloat(property.PropertyLongitude)
          }}
          icon={{
            url: '/images/custom-pin-svg.svg',
            scaledSize: new window.google.maps.Size(30, 30)
          }}
          onClick={() => handlePolygonClick(property)}
        />
      ))}

      {activePolygon && infoWindowPosition && (
        <InfoWindow
          position={infoWindowPosition}
          onCloseClick={() => {
            setActivePolygon(null);
            setInfoWindowPosition(null);
          }}
        >
          <div className="info-window-content">
            <h3>{properties.find(p => p.attomid === activePolygon)?.PropertyAddressFull}</h3>
            <p>{properties.find(p => p.attomid === activePolygon)?.PropertyAddressCity}, {properties.find(p => p.attomid === activePolygon)?.PropertyAddressState}</p>
            <button 
              className="details-button" 
              onClick={() => handleViewDetails(activePolygon)}
              disabled={isLoadingDetails}
            >
              {isLoadingDetails ? 'Loading...' : 'View Details'}
            </button>
          </div>
        </InfoWindow>
      )}

      {poi && isValidCoordinate(poi.PropertyLatitude, poi.PropertyLongitude) && (
        <>
          <Marker
            key="poi-marker"
            position={{ 
              lat: parseFloat(poi.latitude), 
              lng: parseFloat(poi.longitude) 
            }}
            icon={{
              url: '/images/modern-poi-pin-svg.svg',
              scaledSize: new window.google.maps.Size(48, 48)
            }}
          />
          <Circle
            key="poi-circle"
            center={{ 
              lat: parseFloat(poi.PropertyLatitude), 
              lng: parseFloat(poi.PropertyLongitude) 
            }}
            radius={parseFloat(poi.radius) || 1609.34} // Default to 1 mile if not specified
            options={{
              fillColor: '#FF0000',
              fillOpacity: 0.1,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        </>
      )}
    </GoogleMap>
  );
};

export default Map;